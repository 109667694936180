import React from "react";
import SEO from "../components/Seo/seo"
import styled from 'styled-components';
import { responsive } from '../utils/responsive';
import Layout from "../components/Layout/Layout";
import { Link } from "gatsby";
import Carousel from "react-multi-carousel";
import FooterComponent from "../components/Home/Footer/FooterComponent";
import "react-multi-carousel/lib/styles.css";
import { StyledLeftContainer, StyledDescription, StyledButtonWrapper, StyledTitle, StyledLeftContentContainer } from './head';
import { StyledMainVerticalContainer, StyledRightVerticalContainer, StyledVerticalImageWrapper } from './controllers';


// ---- Images ----

import BaseImage1 from '../components/imagesStock/Base/BaseImage1';
import BaseImage2 from '../components/imagesStock/Base/BaseImage2';
import BaseImage3 from '../components/imagesStock/Base/BaseImage3';
import BaseImage5 from '../components/imagesStock/Base/BaseImage5';
import BaseImage6 from '../components/imagesStock/Base/BaseImage6';
import BaseImage7 from '../components/imagesStock/Base/BaseImage7';
import BaseImage8 from '../components/imagesStock/Base/BaseImage8';
import BaseImage9 from '../components/imagesStock/Base/BaseImage9';


// ---- List Styles ----

const StyledBaseImageWrapper = styled(StyledVerticalImageWrapper)`
margin: 0 12.9vw;
`

const BaseSubpage = (props) => {

    return (
        <Layout>
            <SEO title="Base" />
            <StyledMainVerticalContainer>

                <StyledLeftContainer>

                    <StyledLeftContentContainer>

                        <StyledTitle>{props.uri.replace(/[/]/g, "")}</StyledTitle>

                        <StyledDescription>
                            <p>

                                The surface of the base is French-polished, and it features adjustable feet made of brass, finished with natural wool felt.<br /><br />

                                Additionally, a designated space is provided for the power supply and the electronics controlling the lamp.<br /><br />

                                Please click "POWER" to read more about.
                            </p>
                        </StyledDescription>

                        <StyledButtonWrapper>
                            <Link title="This is a link to home page" as="a" to="/" aria-label="home"><h1>Back</h1></Link>
                            <Link title="This is a link to power subpage" as="a" to="/power" aria-label="power"><h1>Power</h1></Link>
                        </StyledButtonWrapper>

                        <FooterComponent />

                    </StyledLeftContentContainer>

                </StyledLeftContainer>

                <StyledRightVerticalContainer>

                    <Carousel
                        swipeable={true}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        ssr={true}
                        keyBoardControl={true}
                        removeArrowOnDeviceType={["tabletPro", "mobile"]}
                    >

                        <StyledBaseImageWrapper>
                            <BaseImage1 />
                        </StyledBaseImageWrapper>

                        <StyledBaseImageWrapper>
                            <BaseImage2 />
                        </StyledBaseImageWrapper>

                        <StyledBaseImageWrapper>
                            <BaseImage7 />
                        </StyledBaseImageWrapper>

                        <StyledBaseImageWrapper>
                            <BaseImage3 />
                        </StyledBaseImageWrapper>

                        <StyledBaseImageWrapper>
                            <BaseImage5 />
                        </StyledBaseImageWrapper>

                        <StyledBaseImageWrapper>
                            <BaseImage6 />
                        </StyledBaseImageWrapper>

                        <StyledBaseImageWrapper>
                            <BaseImage8 />
                        </StyledBaseImageWrapper>

                        <StyledBaseImageWrapper>
                            <BaseImage9 />
                        </StyledBaseImageWrapper>

                    </Carousel>

                </StyledRightVerticalContainer>

            </StyledMainVerticalContainer>

        </Layout>
    )
}

export default BaseSubpage;